@import '../../styles/customMediaQueries.css';
.sticky {
}
.findComfortableplace {
  text-align: center;
  padding: 40px 16px;
  @media (--viewportMedium) {
    padding: 45px 30px;
  }
  @media (--viewportLarge) {
    padding: 50px 40px;
  }
  @media (--viewportLargeWithPaddings) {
    padding: 50px 80px;
  }
  & .finePlaceSec {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: var(--contentMaxWidth);
    margin: 30px auto 40px;
    position: relative;
    padding: 0 30px;

    & .itemSlider {
      width: 100%;

      & .sliderItem {
      }
    }

    & .sliderControls {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--marketplaceColor) !important;
      color: var(--colorWhite) !important;
      padding: 0;
      margin: 0;
      height: 3rem;
      width: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;

      @media (--viewportMedium) {
        height: 3rem;
        width: 3rem;
      }

      @media (--viewportLarge) {
        height: 3rem;
        width: 6rem;
      }

      &:focus {
        outline: none;
      }

      &:hover {
        cursor: pointer;

        & > svg {
          & > path {
            fill: var(--colorWhite);
          }
        }
      }

      &.leftArrow {
        left: 0px;
        background-color: var(--colorGrey100) !important;
        width: 30px;
        height: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 100px !important;
        margin: 0 !important;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: var(--marketplaceColor) !important;
        }
        @media (--viewportLargeWithPaddings) {
          left: -30px;
          width: 40px;
          height: 40px;
        }
        & > svg {
          width: 16px;
          height: 10px;
          @media (--viewportLargeWithPaddings) {
            width: 20px;
            height: 16px;
          }
        }
      }

      &.rightArrow {
        right: 0px;
        background-color: var(--colorGrey100) !important;
        width: 30px;
        height: 30px;
        display: flex !important;
        align-items: center;
        justify-content: center;
        border-radius: 100px !important;
        margin: 0 !important;
        border: none;
        cursor: pointer;

        &:hover {
          background-color: var(--marketplaceColor) !important;
        }

        @media (--viewportLargeWithPaddings) {
          right: -30px;
          width: 40px;
          height: 40px;
        }
        & > svg {
          width: 16px;
          height: 10px;
          @media (--viewportLargeWithPaddings) {
            width: 20px;
            height: 16px;
          }
        }
      }
    }

    & :global(.react-multi-carousel-list) {
      width: 100%;
      display: flex;
      align-items: center;
      overflow: hidden;
      position: initial;
    }

    & :global(.react-multi-carousel-track) {
      list-style: none;
      padding: 0;
      margin: 0;
      display: flex;
      flex-direction: row;
      position: relative;
      transform-style: preserve-3d;
      backface-visibility: hidden;
      will-change: transform, transition;
    }
    & .placeBlock {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-right: 0px;
      text-decoration: none;
      transition: var(--transitionStyle);

      @media (--viewportMedium) {
        margin-right: 30px;
      }
      @media (--viewportLarge) {
        margin-right: 40px;
      }
      @media (min-width: 1300px) {
        margin-right: 75px;
      }
      &:last-child {
        margin-right: 0;
      }
      &:hover,
      &.active {
        transition: var(--transitionStyle);
        & .placeName {
          color: var(--marketplaceColor);
        }
        & .placeIcon {
          background-color: var(--marketplaceColor);
          transition: var(--transitionStyle);
          & > svg {
            & path {
              fill: var(--colorWhite);
              transition: var(--transitionStyle);
            }
            &.iconCondo,
            &.iconHouse,
            &.iconLoft {
              & > path {
                fill: none;
                stroke: #fff;
              }
            }
          }
        }
      }
      & .placeIcon {
        width: 60px;
        height: 60px;
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        transition: var(--transitionStyle);
        padding: 18px;
        background-color: #f9f9f9;
        @media (--viewportMedium) {
          width: 80px;
          height: 80px;
          padding: 24px;
        }
        @media (min-width: 1280px) {
          width: 100px;
          height: 100px;
          padding: 34px;
        }
        & > svg {
          fill: none;
          transition: var(--transitionStyle);
        }
      }
      & .placeName {
        font-weight: var(--fontWeightSemiBold);
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        color: var(--marketplaceBlackColor);
        transition: var(--transitionStyle);
        @media (--viewportMedium) {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
  }
  & .findMore {
    margin-top: 30px;
    display: inline-block;
    background-color: var(--marketplaceColor);
    color: var(--colorWhite);
    border: none;
    transition: var(--transitionStyle);
    font-weight: var(--fontWeightMedium);
    font-size: 16px;
    line-height: 24px;
    padding: 8px 16px;
    border-radius: 10px;
    @media (--viewportLargeWithPaddings) {
      padding: 10px 60px;
    }
    &:hover {
      transition: var(--transitionStyle);
      text-decoration: none;
      background-color: var(--marketplaceColorDark);
      color: var(--colorWhite);
    }
  }
}
.sectionTitle {
  composes: h2 from global;
}
.sectionSubHeading {
  composes: sectionSubHeading from global;
}
.trendingDestination {
  margin-top: 40px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 20px;
  @media (--viewportSmall) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportMedium) {
    margin-top: 45px;
    grid-template-columns: repeat(3, 1fr);
  }
  @media (--viewportLargeWithPaddings) {
    grid-gap: 30px;
    margin-top: 50px;
  }
  & .locationBlock {
    position: relative;
    border-radius: 6px;
    overflow: hidden;
    transition: var(--transitionStyle);
    &:hover {
      transition: var(--transitionStyle);
      & > img {
        transform: scale(1.05);
        transition: var(--transitionStyle);
      }
      & .locationTitle {
        color: var(--marketplaceColor);
        transition: var(--transitionStyle);
      }
    }
    &::after {
      content: '';
      position: absolute;
      z-index: 0;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(180.65deg, rgba(3, 3, 3, 0) 52.91%, rgba(0, 0, 0, 0.61) 86.14%);
    }
    & > img {
      max-width: 100%;
      width: 100%;
      transition: var(--transitionStyle);
      transform: scale(1);
    }
    & .locationTitle {
      position: absolute;
      z-index: 1;
      padding: 28px;
      bottom: 0;
      left: 0;
      width: 100%;
      text-align: center;
      font-weight: 600;
      font-size: 24px;
      line-height: 30px;
      color: var(--colorWhite);
      transition: var(--transitionStyle);
      @media (min-width: 1280px) {
        padding: 38px;
        font-size: 28px;
        line-height: 38px;
      }
    }
  }
}
.testimonialSec {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  margin-top: 50px;
  @media (--viewportLarge) {
    margin-top: 60px;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportLargeWithPaddings) {
    grid-gap: 34px;
    margin-top: 80px;
  }
  & .testimonialBlock {
    cursor: pointer;
    background: #f9f9f9;
    border: 1px solid #eee8e8;
    border-radius: 20px;
    text-align: left;
    transition: var(--transitionStyle);
    padding: 20px 30px;
    @media (--viewportLargeWithPaddings) {
      padding: 30px 50px;
    }
    &:hover {
      transition: var(--transitionStyle);
      background-color: var(--marketplaceColor);

      & .comments {
        color: #fff7f8;
      }
      & .quotes {
        & > svg {
          & > path {
            fill: var(--colorWhite);
          }
        }
      }
      & .userDetails {
        & .userInfo {
          & .userName {
            color: var(--colorWhite);
          }
          & .userLocation {
            color: #ffc9d5;
          }
        }
      }
    }
    & .quotes {
      margin-bottom: 14px;
      @media (--viewportLargeWithPaddings) {
        margin-bottom: 24px;
      }
      & > svg {
        width: 38px;
        @media (--viewportLarge) {
          width: 48px;
        }
      }
    }
    & .comments {
      font-weight: 300;
      font-size: 16px;
      line-height: 24px;
      color: #050102;
      margin: 0 0 0px 0;
      @media (--viewportMedium) {
        font-size: 18px;
        line-height: 30px;
      }
    }
    & .userDetails {
      display: flex;
      align-items: center;
      margin-top: 28px;
      @media (--viewportLargeWithPaddings) {
        margin-top: 38px;
      }
      & .userProfile {
        width: 40px;
        height: 40px;
        border: 3px solid #ffffff;
        border-radius: 100px;
        overflow: hidden;
        @media (--viewportMedium) {
          width: 50px;
          height: 50px;
        }
        & > img {
          object-fit: cover;
          width: 100%;
          height: 100%;
          max-width: 100%;
        }
      }
      & .userInfo {
        width: calc(100% - 40px);
        padding-left: 10px;
        @media (--viewportMedium) {
          width: calc(100% - 50px);
          padding-left: 20px;
        }
        @media (--viewportLargeWithPaddings) {
          width: calc(100% - 72px);
          padding-left: 24px;
        }
        & .userName {
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          margin: 0;
          padding: 0;
          color: #1e1e1e;
          @media (--viewportMedium) {
            font-size: 18px;
            line-height: 24px;
          }
        }
        & .userLocation {
          font-weight: 400;
          font-size: 14px;
          line-height: 18px;
          margin: 0;
          padding: 0;
          color: #8c8385;
          @media (--viewportMedium) {
            font-size: 15px;
            line-height: 20px;
          }
        }
      }
    }
  }
}
.amazingPlacesSection {
  background-image: url('../../assets/amazing-places-bg.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 10px;
  overflow: hidden;
  border-radius: 40px;
  position: relative;
  &::before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2c030a;
    opacity: 0.4;
    content: '';
    z-index: 0;
    @media (--viewportMedium) {
      opacity: 0.2;
    }
  }
  & .bannerContent {
    text-align: left;
    max-width: 100%;
    width: 100%;
    padding: 60px 30px;
    color: var(--colorWhite);
    position: relative;
    z-index: 1;
    @media (--viewportMedium) {
      max-width: 70%;
      padding: 100px 30px;
    }
    @media (min-width: 1440px) {
      padding: 145px 50px;
      max-width: 50%;
    }
    & > h3 {
      font-weight: 600;
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 6px 0;
      padding: 0;
      color: var(--colorWhite);
      @media (--viewportLarge) {
        margin: 0 0 10px 0;
        font-size: 24px;
        line-height: 34px;
      }
    }
    & > h2 {
      font-weight: 700;
      font-size: 30px;
      line-height: 48px;
      margin: 0 0 10px 0;
      padding: 0;
      color: var(--colorWhite);
      @media (--viewportLarge) {
        font-size: 40px;
        line-height: 58px;
        margin: 0 0 10px 0;
      }
      @media (--viewportLargeWithPaddings) {
        font-size: 50px;
        line-height: 68px;
        margin: 0 0 12px 0;
      }
    }
    & > p {
      font-weight: 400;
      font-size: 18px;
      line-height: 24px;
      margin: 0 0 20px 0;
      padding: 0;
      color: var(--colorWhite);
      @media (--viewportLargeWithPaddings) {
        font-size: 20px;
        line-height: 30px;
        margin: 0 0 28px 0;
      }
    }
    & .bookNowBtn {
      background-color: var(--marketplaceColor);
      color: var(--colorWhite);
      border: none;
      transition: var(--transitionStyle);
      font-weight: var(--fontWeightMedium);
      font-size: 16px;
      line-height: 20px;
      padding: 8px 16px;
      border-radius: 10px;
      display: inline-block;
      cursor: pointer;
      @media (--viewportLarge) {
        font-size: 18px;
        line-height: 24px;
        padding: 8px 20px;
      }
      @media (--viewportLargeWithPaddings) {
        font-size: 20px;
        line-height: 30px;
        padding: 10px 50px;
      }
      &:hover {
        transition: var(--transitionStyle);
        text-decoration: none;
        background-color: var(--marketplaceColorDark);
        color: var(--colorWhite);
      }
    }
  }
}
.findComfortableplace {
  position: relative;
  width: 100%;
  z-index: 0;
  & .findPlaceHead {
    text-align: left;
    margin-bottom: 30px;
  }
  & :global(.react-multi-carousel-list) {
    margin: 0 -12px;
  }
  & :global(.react-multi-carousel-item) {
    padding: 24px 12px;
  }
  & :global(.react-multi-carousel-dot > button) {
    background-color: #9cadd8;
    border-color: #9cadd8;
    width: 12px;
    height: 12px;
    margin-right: 6px;
    @media (--viewportMedium) {
      width: 14px;
      height: 14px;
      margin-right: 8px;
    }
  }
  & :global(.react-multi-carousel-dot button:hover:active) {
    background-color: #9cadd8;
    border-color: #9cadd8;
    width: 19px;
    height: 19px;
  }
  & :global(.react-multi-carousel-dot.react-multi-carousel-dot--active > button) {
    background-color: var(--marketplaceColor);
    border-color: var(--marketplaceColor);
  }
  & :global(.react-multiple-carousel__arrow) {
    min-width: 34px;
    min-height: 34px;
    top: 25%;
    @media (min-width: 580px) {
      top: 0;
    }
  }
  & :global(.react-multiple-carousel__arrow--left) {
    @media (min-width: 580px) {
      right: 50px;
      left: auto;
    }
  }
  & :global(.react-multiple-carousel__arrow--right) {
    @media (min-width: 580px) {
      right: 10px;
    }
  }
  & :global(.react-multiple-carousel__arrow::before) {
    font-size: 14px;
  }
  & :global(.react-multiple-carousel__arrow:hover) {
    background-color: var(--marketplaceColor);
  }
}
.featuredCardSec {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;
  @media (min-width: 700px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (--viewportLarge) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1440px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.faqSection {
  width: 100%;
  padding: 30px 0px;
  & h2 {
    font-weight: var(--fontWeightBold);
    letter-spacing: 0em;
    color: var(--colorGrey900);
    margin: 0px 0 30px 0;
    padding: 0;
    font-size: 24px;
    line-height: 130%;
    @media (--viewportMedium) {
      font-size: 28px;
      margin: 0px 0 40px 0;
    }
    @media (--viewportLarge) {
      text-align: center;
      font-size: 32px;
      margin: 0px 0 60px 0;
    }
  }

  & .faqCard {
    border: 1px solid var(--colorGrey100);
    margin-bottom: 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 10px;
    background-color: #f6f6f6;
    transition: all 0.5s ease-in-out;
    &.activeFAQ {
      background-color: var(--colorWhite);
      transition: all 0.5s ease-in-out;

      & .faqDescription {
        transition: all 0.5s ease-in-out;
      }
    }
    & .faqTitle {
      background-color: var(--colorGrey100);
      padding: 20px;
      font-weight: var(--fontWeightBold);
      display: flex;
      align-items: center;
      justify-content: space-between;
      & .faqHeading {
        font-size: 16px;
        color: var(--colorBlack);
      }
      & .faqIcon {
        font-size: 30px;
        font-weight: 400;
        transition: all ease 0.5s;
      }
    }
    & .faqDescription {
      padding: 20px;
      transition: all 0.5s ease-in-out;

      & .sliderItem {
        & .sliderItemInfo {
          margin-bottom: 0;
          & p {
            margin: 0;
          }
        }
      }
    }
  }
}
.pageContent {
}

.moreAbout {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  & > span {
    color: var(--marketplaceColor);
    padding: 10px 20px;
    text-align: center;
    background-color: var(--colorWhite);
    border: solid 1px var(--marketplaceColor);
    border-radius: 9px;
    cursor: pointer;
    transition: var(--transitionStyle);
    &:hover {
      background-color: var(--marketplaceColor);
      border-color: var(--marketplaceColor);
      color: var(--colorWhite);
    }
  }
}
.moreInfoLink {
  cursor: pointer;
}
.propertySection {
  padding: 40px 24px;
  @media (--viewportLarge) {
    padding: 60px 24px;
  }
  @media (--viewportLarge) {
    padding: 80px 24px;
  }
  & .contentWidth {
    max-width: var(--contentMaxWidthPages);
    width: 100%;
    margin: 0 auto;
    & .sectionContent {
      display: flex;
      width: 100%;
      flex-direction: column;
      @media (--viewportLarge) {
        flex-direction: row;
      }
      & .sectionHead {
        width: 100%;
        margin-bottom: 5%;
        @media (--viewportLarge) {
          display: flex;
          flex-direction: column;
          justify-content: center;
          width: 40%;
          padding-right: 10%;
          margin-bottom: 0;
        }
        & > h2 {
          color: var(--marketplaceColor);
          font-size: 18px;
          line-height: 110%;
          text-transform: uppercase;
          margin: 0 0 8px 0;
          font-weight: var(--fontWeightRegular);
          @media (--viewportLarge) {
            font-size: 20px;
          }
        }
        & > h1 {
          color: var(--colorGrey900);
          font-size: 30px;
          line-height: 110%;
          margin: 14px 0;
          font-weight: var(--fontWeightBold);
          @media (--viewportLarge) {
            font-size: 40px;
          }
        }
        & > p {
          color: var(--colorGrey600);
          font-size: 16px;
          line-height: 140%;
          font-weight: var(--fontWeightRegular);
          margin: 0;
          @media (--viewportLarge) {
            font-size: 18px;
          }
        }
      }
      & .hospitalitySec {
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 32px;
        @media (--viewportMedium) {
          grid-template-columns: repeat(3, 1fr);
        }
        @media (--viewportLarge) {
          width: 60%;
        }
      }
      & .infoBlock {
        &:first-child {
          & .infoIcon {
            background-color: rgb(255 170 0 / 15%);
            & > svg {
              & path {
                stroke: var(--colorAttention);
              }
            }
          }
        }
        &:nth-child(2) {
          & .infoIcon {
            background-color: rgb(62 91 166 / 15%);
            & > svg {
              & path {
                fill: var(--marketplaceColor);
                &:first-child {
                  stroke: var(--marketplaceColor);
                  fill: none;
                }
              }
            }
          }
        }
        &:last-child {
          & .infoIcon {
            background-color: rgb(46 204 113 / 15%);
            & > svg {
              & path {
                fill: var(--colorSuccess);

                &:not(:first-child) {
                  fill: none;
                  stroke: var(--colorSuccess);
                }
              }
              & rect {
                fill: var(--colorSuccess);
              }
            }
          }
        }
        & .infoIcon {
          width: 85px;
          height: 85px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          padding: 16px;
          border-radius: 100px;
          margin-bottom: 30px;
          & > svg {
            width: 55px;
            height: 55px;
            position: relative;
            left: 50%;
          }
        }

        & > h3 {
          color: var(--colorGrey900);
          font-size: 16px;
          line-height: 110%;
          margin: 0 0 4px 0;
          font-weight: var(--fontWeightBold);
        }
        & > ul {
          margin: 0;
          list-style-type: disc;
          & > li {
            list-style-position: outside;
            display: flex;
            color: var(--colorGrey600);
            font-size: 15px;
            line-height: 140%;
            font-weight: var(--fontWeightRegular);
            position: relative;
            &::before {
              position: relative;
              content: ' ';
              width: 5px;
              height: 5px;
              border-radius: 100px;
              background-color: var(--colorGrey600);
              margin-top: 10px;
            }
            &:not(:last-child) {
              margin-bottom: 8px;
            }
            & > svg {
              min-width: 20px;
              margin-right: 10px;
            }
            & > span {
              padding-left: 10px;
              width: calc(100% - 10px);
              & > a {
                text-decoration: underline;
                display: inline-block;
                padding: 0 4px;
              }
            }
          }
        }
      }
    }
  }
}
